.bookingPopover div span {
  background-color: #fff;
}

.bookingPopover div button[aria-selected=true] {
  color: #fff !important;
}

.bookingPopover div button[aria-selected=false] {
  color: #91E3D0 !important;
}

.bookingZoneUnfocussedDesk {
  opacity: 0.9;
}

line {
  stroke: black;
}